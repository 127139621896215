import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentCode = makeShortcode("ComponentCode");
const ComponentDocs = makeShortcode("ComponentDocs");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "progress-indicator"
    }}>{`Progress indicator`}</h2>
    <ComponentCode name="Progress indicator" component="progress-indicator" variation="progress-indicator" hasReactVersion="progressindicator--default" hasVueVersion="progress--default" hasAngularVersion="?path=/story/progress-indicator--basic" codepen="dLMppM" mdxType="ComponentCode"></ComponentCode>
    <h2 {...{
      "id": "progress-indicator-vertical"
    }}>{`Progress indicator vertical`}</h2>
    <ComponentCode name="Progress indicator vertical" component="progress-indicator" variation="progress-indicator--vertical" hasAngularVersion="?path=/story/progress-indicator--vertical" codepen="QPNKpB" mdxType="ComponentCode"></ComponentCode>
    <h2 {...{
      "id": "documentation"
    }}>{`Documentation`}</h2>
    <ComponentDocs component="progress-indicator" mdxType="ComponentDocs"></ComponentDocs>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      